import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { HUBSPOT_PROXY_URL, blockedDomains, emailRegex } from "../../constants";
import axios from "axios";
import { Link } from "gatsby-link";
import { RegisteredIcon } from "../../assets/icons/RegisteredIcon";
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  OPTIONAL_VALIDATION,
  PHONE_VALIDATION,
  WORK_EMAIL_VALIDATION,
} from "../../utilities/helpers";

const SuccessModal = () => {
  return (
    <div className="flex items-center justify-center h-full text-center ">
      <div className=" lg:h-[428px] w-full max-w-xl transform overflow-hidden rounded-3.5xl bg-white px-5 py-10 sm:px-9 sm:py-9 text-left align-middle shadow-xl transition-all flex flex-col justify-center items-center gap-3 lg:gap-9">
        <RegisteredIcon />
        <div className="flex flex-col items-center gap-3 lg:gap-5">
          <h1 className="text-xl sm:text-2.5xl font-manrope font-semibold tracking-tightest_6 text-gray-2700">
            You’ve been registered!
          </h1>
          <p className="text-center max-w-[434px] font-manrope text-xs sm:text-base font-medium leading-5 tracking-tight_1 text-gray-1200 ">
            Thank you for registering to the webinar. We will send through the
            dial-link via email so please check your inbox. See you at the
            webinar!
          </p>
        </div>
      </div>
    </div>
  );
};

const RegisterForm = ({
  location,
  gated,
  setGated,
  addRevenueHero,
  formId = "b1f2fd0e-9b34-4a84-bf8d-c2ad4b28fd3e",
  routerId = "231",
  formClass,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const schema = {
    // firstName: FIRST_NAME_VALIDATION,
    // lastName: LAST_NAME_VALIDATION,
    workEmail: WORK_EMAIL_VALIDATION,
    // phone: PHONE_VALIDATION,
    // useCaseFreeTextForm: OPTIONAL_VALIDATION,
    // jobTitle: OPTIONAL_VALIDATION,
  };
  
  if (formId === "ff7accdf-7dcf-4865-a5c5-75eee78d34d0") {
    Object.assign(schema, {
      would_you_like_to_attend_the_medical_or_non_medical_webinar_:
        Yup.array().of(Yup.string().required()),
    });
  }
  const RequestTrialSchema = Yup.object().shape(schema);

  const handleSubmit = async (values, { resetForm }) => {
    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (emailRegex.test(values?.workEmail)) {
        setLoading(true);

        const requestPayload = {
          fields: [
            // {
            //   objectTypeId: "0-1",
            //   name: "firstname",
            //   value: values?.firstName,
            // },
            // {
            //   objectTypeId: "0-1",
            //   name: "lastname",
            //   value: values?.lastName,
            // },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.workEmail,
            },
            // {
            //   objectTypeId: "0-1",
            //   name: "jobtitle",
            //   value: values?.jobTitle,
            // },
            // {
            //   objectTypeId: "0-1",
            //   name: "use_case",
            //   value: values?.useCase,
            // },
            // {
            //   objectTypeId: "0-1",
            //   name: "use_case_free_text_form",
            //   value: values?.useCaseFreeTextForm,
            // },
          ].filter((x) => x.value),
        };
        if (
          formId === "ff7accdf-7dcf-4865-a5c5-75eee78d34d0" &&
          values?.would_you_like_to_attend_the_medical_or_non_medical_webinar_
            ?.length
        ) {
          values?.would_you_like_to_attend_the_medical_or_non_medical_webinar_?.forEach(
            (x) =>
              requestPayload?.fields.push({
                objectTypeId: "0-1",
                name: "would_you_like_to_attend_the_medical_or_non_medical_webinar_",
                value: x,
              })
          );
        }
        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              pageUri: location?.href,
              pageName: "try-it-free",
            },
          });
        }

        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId,
          })
        );
        if (gated) {
          localStorage.setItem("webinar_form_submitted", 1);
          setGated(false)
        }
        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: values?.workEmail,
            // use_case: values?.useCaseFreeTextForm,
          });
        }
        resetForm();
        if (addRevenueHero) {
          const _res = await window?.hero?.submit({
            email: values?.workEmail,
            // use_case: values?.useCaseFreeTextForm,
          });
          await window?.hero?.dialog?.open(_res);
        }
        // handleLoad();
        setIsSuccess(true);
      } else {
        setError("Email must be formatted correctly.");
      }
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("SERVER SIDE ERROR");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isSuccess ? (
        <SuccessModal />
      ) : (
        <div className="flex items-center justify-center text-center">
          <div className="w-full max-w-xl transform overflow-hidden rounded-3.5xl bg-white px-5 py-10 sm:px-9 sm:py-9 text-left align-middle shadow-xl transition-all">
            <div className="flex items-center justify-between mt-5 sm:mt-0">
              <h1 className="text-2xl font-semibold tracking-tighter_1 text-purple-1100">
                Register Now
              </h1>
            </div>

            <div className="mt-2.5">
              <p className="mb-2 text-xs font-medium sm:text-sm text-gray-1200 tracking-tighter_1 sm:mb-6">
                Fill out your details below and we’ll send through the dial-in link. If you can't make it fill out the form and we'll send you the webinar recording
              </p>
              <Formik
                initialValues={{
                  // firstName: "",
                  // lastName: "",
                  workEmail: "",
                  // jobTitle: "",
                  // useCase: "",
                  // useCaseFreeTextForm: "",
                  // would_you_like_to_attend_the_medical_or_non_medical_webinar_:
                  //   "",
                }}
                validationSchema={RequestTrialSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  values,
                  setTouched,
                  validateForm,
                  setFieldValue,
                  form,
                  isValid,
                  dirty,
                  ...rest
                }) => {
                  return (
                    <Form className={formClass || "webinar-form"}>
                      <section className="flex flex-col sm:gap-y-2.5 gap-y-2">
                        {/* <div className="flex flex-col gap-x-7.5 sm:flex-row gap-y-2 sm:gap-y-0">
                          <div className="flex flex-col w-full">
                            <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs mb-1.5 font-medium">
                              First name
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="firstName"
                              placeholder="Enter your first name"
                              type="text"
                              className="text-gray-2700 w-full focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                            />
                            {errors.firstName && touched.firstName ? (
                              <small className="text-red-500">
                                {errors.firstName}
                              </small>
                            ) : null}
                          </div>

                          <div className="flex flex-col w-full">
                            <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                              Last name
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="lastName"
                              placeholder="Enter your last name"
                              type="text"
                              className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm w-full rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                            />
                            {errors.lastName && touched.lastName ? (
                              <small className="text-red-500">
                                {errors.lastName}
                              </small>
                            ) : null}
                          </div>
                        </div> */}

                        <div className="flex flex-col flex-1 w-full">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                            Work email
                            <sup className="text-red-500">*</sup>
                          </label>
                          <Field
                            name="workEmail"
                            placeholder="Enter your work email"
                            type="text"
                            className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                          />
                          {errors.workEmail && touched.workEmail ? (
                            <small className="text-red-500">
                              {errors.workEmail}
                            </small>
                          ) : null}
                        </div>

                        {/* <div className="flex flex-col flex-1 w-full">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                            Job title{" "}
                            <span className="text-gray-1200">{`(optional)`}</span>
                          </label>
                          <Field
                            name="jobTitle"
                            placeholder="Enter your role"
                            type="text"
                            className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-5"
                          />
                          {errors.jobTitle && touched.jobTitle ? (
                            <small className="text-red-500">
                              {errors.jobTitle}
                            </small>
                          ) : null}
                        </div>

                        <div className="flex flex-col w-full">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                            What questions do you have for the speakers?{" "}
                            <span className="text-gray-1200">{`(optional)`}</span>
                          </label>
                          <Field
                            as="textarea"
                            name="useCaseFreeTextForm"
                            placeholder="Enter any questions you may have"
                            className="text-gray-2700 placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border min-h-[96px] py-2.5 px-5"
                          />
                          {errors.useCaseFreeTextForm &&
                            touched.useCaseFreeTextForm ? (
                            <small className="text-red-500">
                              {errors.useCaseFreeTextForm}
                            </small>
                          ) : null}
                        </div> */}

                        {formId === "ff7accdf-7dcf-4865-a5c5-75eee78d34d0" && (
                          <section
                            role="group"
                            aria-labelledby="checkbox-group"
                          >
                            <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                              Would you like to attend the medical or
                              non-medical webinar?
                              <span className="text-gray-1200">{`(optional)`}</span>
                            </label>

                            <section className="flex items-center gap-4 mt-1">
                              <label className="flex items-center gap-2 text-sm">
                                <Field
                                  type="checkbox"
                                  name="would_you_like_to_attend_the_medical_or_non_medical_webinar_"
                                  value="Consensus Webinar - Medical"
                                />
                                Medical
                              </label>
                              <label className="flex items-center gap-2 text-sm">
                                <Field
                                  type="checkbox"
                                  name="would_you_like_to_attend_the_medical_or_non_medical_webinar_"
                                  value="Consensus Webinar - Non-Medical"
                                />
                                Non-Medical
                              </label>
                            </section>
                          </section>
                        )}
                        <div className="flex w-full gap-5">
                          <button
                            type={
                              Object.keys(errors)?.length ? "button" : "submit"
                            }
                            disabled={loading || !(isValid && dirty)}
                            className="bg-purple-1500 hover:bg-[#5E24FF] font-semibold font-manrope text-white py-2 px-7 text-sm sm:text-base shadow-lg rounded-full w-full mx-auto sm:mt-4 mt-2 tracking-tighter_1 mb-5 disabled:cursor-not-allowed"
                            {...rest}
                          >
                            {loading ? (
                              <div role="status">
                                <div className="flex gap-x-2.5 justify-center items-center">
                                  <span>Registering</span>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="19"
                                      viewBox="0 0 18 19"
                                      fill="none"
                                      className="inline animate-spin"
                                    >
                                      <g clip-path="url(#clip0_88_6703)">
                                        <path
                                          d="M9 18.5C7.78535 18.5 6.60586 18.2627 5.49668 17.7934C4.42441 17.3398 3.46289 16.6895 2.63672 15.8633C1.81055 15.0371 1.16016 14.0756 0.706641 13.0033C0.237305 11.8941 0 10.7146 0 9.5C0 9.1502 0.283008 8.86719 0.632812 8.86719C0.982617 8.86719 1.26562 9.1502 1.26562 9.5C1.26562 10.5441 1.46953 11.5566 1.87383 12.5111C2.26406 13.4322 2.82129 14.2602 3.53145 14.9703C4.2416 15.6805 5.06953 16.2395 5.99062 16.6279C6.94336 17.0305 7.95586 17.2344 9 17.2344C10.0441 17.2344 11.0566 17.0305 12.0111 16.6262C12.9322 16.2359 13.7602 15.6787 14.4703 14.9686C15.1805 14.2584 15.7395 13.4305 16.1279 12.5094C16.5305 11.5566 16.7344 10.5441 16.7344 9.5C16.7344 8.45586 16.5305 7.44336 16.1262 6.48887C15.7373 5.56999 15.1744 4.73494 14.4686 4.02969C13.7641 3.3229 12.9288 2.75991 12.0094 2.37207C11.0566 1.96953 10.0441 1.76562 9 1.76562C8.6502 1.76562 8.36719 1.48262 8.36719 1.13281C8.36719 0.783008 8.6502 0.5 9 0.5C10.2146 0.5 11.3941 0.737305 12.5033 1.20664C13.5756 1.66016 14.5371 2.31055 15.3633 3.13672C16.1895 3.96289 16.8381 4.92617 17.2916 5.99668C17.7609 7.10586 17.9982 8.28535 17.9982 9.5C17.9982 10.7146 17.7609 11.8941 17.2916 13.0033C16.8398 14.0756 16.1895 15.0371 15.3633 15.8633C14.5371 16.6895 13.5738 17.3381 12.5033 17.7916C11.3941 18.2627 10.2146 18.5 9 18.5Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_88_6703">
                                          <rect
                                            width="18"
                                            height="18"
                                            fill="white"
                                            transform="translate(0 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              "Register"
                            )}
                          </button>
                        </div>
                      </section>
                    </Form>
                  );
                }}
              </Formik>

              <p className="text-xs text-center text-gray-1200">
                By pressing "register", you agree to Encord's{" "}
                <Link
                  to="/terms-and-conditions-uk"
                  target="_blank"
                  className="underline"
                >
                  terms and conditions
                </Link>{" "}
                and{" "}
                <Link to="/privacy" target="_blank" className="underline">
                  privacy policy
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterForm;
